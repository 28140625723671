@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700,900&display=swap');
/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-collapse: collapse;
  outline: none;
  scrollbar-width: thin;
  scrollbar-color: lightgray transparent;
}

:root {
  --primary-color: #02b9cf;
  --strong-primary: #02aec7;
  --main-background-color: #f2f2f2;
  --orange: #ffb04c;
  --full-white: #ffffff;
  --gray100: #f2f2f2;
  --gray200: #bebebe;
  --gray300: #bebebe;
  --gray400: #bebebe;
  --gray500: #8c8c8c;
  --gray600: #848383;
  --gray700: #525252;
  --gray800: #525252;
  --gray900: #454545;
  --gray1000: #232323;
  --card-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  --card-radius: 8px;
  --body: #f6f6f6;
  --disabled-color: #e4e2e2;
}

.bg-primary {
  background-color: var(--primary-color);
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--body);
  color: var(--gray900);
  font-family: 'Roboto', sans-serif;
}

.hr-line {
  height: 1px;
  background-color: #dbdbdb;
  width: 100%;
  margin: auto;
}

p {
  margin-bottom: 14px;
}

pre {
  font-family: 'Roboto', sans-serif;
  white-space: pre-wrap;
}

h1 {
  font-size: 38px;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  font-weight: bold;
}

h3 {
  font-size: 30px;
  font-weight: bold;
}

h4 {
  font-size: 26px;
  font-weight: bold;
}

.g-title {
  font-size: 20px;
  font-weight: 600;
  color: #5c5a5a;
}

.g-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #232323;
}

.g-text {
  font-size: 16px;
  font-weight: 400;
  color: #878789;
}

.g-lg-gray {
  font-size: 20px;
  color: #5c5a5a;
}

.g-sml-gray {
  font-size: 16;
  color: #232323;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

a:hover {
  /* color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

a:active {
  outline: none;
}

svg {
  display: initial;
  vertical-align: initial;
}

input:not([type='checkbox']),
.g-input[type='text'],
textarea {
  -webkit-appearance: none;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  padding: 8px 12px;
  outline: none;
  border: 1px solid #dddddd;
  font-size: 16px;
  transition: box-shadow 0.3s;
}

textarea {
  border-radius: 15px;
}

input:not([type='checkbox']):focus,
textarea:focus,
select:focus {
  z-index: 2;
  outline: none;
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
}

.focus-shadow:focus {
  z-index: 2;
  outline: none;
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3) !important;
}

input:not([type='checkbox']):disabled,
select:disabled,
textarea:disabled {
  background-color: var(--disabled-color);
}

select {
  background: url('data:image/svg+xml, %3Csvg%20width%3D%2210%22%20height%3D%229%22%20viewBox%3D%220%200%2016%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M8%209L0.205772%201.4682e-06L15.7942%201.05412e-07L8%209Z%22%20fill%3D%22%2367C4D7%22%2F%3E%0A%3C%2Fsvg%3E')
    no-repeat 90% 50% #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 25px;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  padding: 8px 12px;
  font-size: 16px;
  transition: box-shadow 0.3s;
}

label {
  color: #848383;
}

.g-invalid-input:required:invalid {
  /* -webkit-appearance: none; */
  z-index: 2;
  outline: none;
  box-shadow: 0 0 0 1px rgba(255, 0, 0, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(255, 0, 0, 0.3);
}

.g-invalid-feedback {
  margin: 2px 0;
  color: red;
  font-size: 12px;
  min-height: 13px;
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

button {
  transition: box-shadow 0.3s;
}

button:focus,
.g-blue-button:focus,
.g-orange-button:focus,
button:active,
.g-blue-button:active,
.g-orange-button:active {
  outline: none;

  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
}

.g-blue-button {
  background: var(--primary-color);
  border-radius: 25px;
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 7px;
  padding-left: 20px;
  white-space: nowrap;
  padding-right: 20px;
  border: none;
  text-align: center;
  outline: none;
  cursor: pointer;
}
.g-blue-button-2 {
  color: var(--primary-color);
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 2px;
  white-space: nowrap;
  border: none;
  text-align: center;
  outline: none;
  cursor: pointer;

  text-decoration: underline;
}

.g-outline-button {
  background: transparent;
  border-color: var(--primary-color);
  border-width: 1px;
  border-radius: 25px;
  color: var(--primary-color);
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 7px;
  padding-left: 20px;
  white-space: nowrap;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.g-blue-button:hover {
  filter: brightness(95%);
  transition: all 0.2s;
}

.g-orange-button {
  background: var(--orange);
  border-radius: 25px;
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 7px;
  padding-left: 20px;
  white-space: nowrap;
  padding-right: 20px;
  border: none;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.g-orange-button:hover {
  filter: brightness(98%);
  transition: all 0.2s;
}

.g-danger-button {
  background: transparent;
  border-radius: 25px;
  border: 1px solid red;
  color: red;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 7px;
  padding-left: 20px;
  white-space: nowrap;
  padding-right: 20px;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.g-danger-button:hover {
  filter: brightness(95%);
  transition: all 0.5s;
  background: red;
  border: 1px solid red;
  color: white;
}

.g-card {
  background-color: var(--full-white);
  box-shadow: var(--card-shadow);
  border-radius: var(--card-radius);
}

.g-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

/** NProgress bar */
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 25px;
  height: 25px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.muted-text {
  color: var(--gray300);
}

.bold-text {
  font-weight: 700;
}

.g-row {
  display: flex;
}

.g-col {
  display: flex;
  flex-direction: column;
}

.g-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 15px 25px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 25px;
}

.g-patient-area {
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

hr {
  height: 1px;
  background-color: #dbdbdb;
  border-top-width: 0;
}

.border-shadow {
  border-radius: 16px;
  box-shadow: -3.9px 0.9px 13px #4d4d4d1a;
}

.opacity-transition {
  transition: visibility 0.3s linear, opacity 0.3s ease-in-out;
}

.default-checkbox {
  background-color: initial;
  cursor: default;
  -webkit-appearance: checkbox;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

.default-checkbox:focus {
  background-color: initial;
  cursor: default;
  -webkit-appearance: checkbox;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

.date-input {
  /* border: none !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06) !important; */
}

.multi-select {
  --rmsc-main: #02b9cf !important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ddd !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: #f5f5f5 !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  --rmsc-p: 5px !important; /* Spacing */
  --rmsc-radius: 25px !important; /* Radius */
  --rmsc-h: 26px !important; /* Height */
  font-size: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s;
}

.dropdown-heading-value {
  margin-left: 0.5rem !important;
  color: #848383 !important;
}

.dropdown-heading-dropdown-arrow {
  display: none !important;
}
